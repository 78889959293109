import React from "react";

const skillsContent = [
  { skillClass: "p95", skillPercent: "95", skillName: "MERN" },
  { skillClass: "p95", skillPercent: "95", skillName: "REACT / Redux" },
  { skillClass: "p95", skillPercent: "95", skillName: "React Native" },
  { skillClass: "p95", skillPercent: "95", skillName: "Next" },
  { skillClass: "p95", skillPercent: "95", skillName: "Vue" },
  { skillClass: "p99", skillPercent: "99", skillName: "Material-UI / TailwindCSS" },
  { skillClass: "p99", skillPercent: "99", skillName: "Python/Django" },
  { skillClass: "p98", skillPercent: "98", skillName: "Typescript" },
];

const Skills = () => {
  return (
    <>
      {skillsContent.map((val, i) => (
        <div className="col-6 col-md-3 mb-3 mb-sm-5" key={i}>
          <div className={`c100 ${val.skillClass}`}>
            <span>{val.skillPercent}%</span>
            <div className="slice">
              <div className="bar"></div>
              <div className="fill"></div>
            </div>
          </div>
          <h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
            {val.skillName}
          </h6>
        </div>
      ))}
    </>
  );
};

export default Skills;
